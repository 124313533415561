import {useContext} from "react";
import {CLEAR_STATE, SEARCH_FOR_EVENT} from "/context/filter/filter-actions";
import FilterEventContext from "/context/filter/FilterContext";
import {SvgSearchIcon} from "/components/shared/SvgIcons";
import {useRouter} from 'next/router'

export default function Search() {
    const {state: {searchQuery}, dispatch} = useContext(FilterEventContext);
    const router = useRouter();

    const handleSearchClick = () => {
        if (router.pathname === '/') {
            router.push('/productions')
        }
    }

    const handleChange = (e) => {
        if (e.target.value !== '') {
            dispatch({type: SEARCH_FOR_EVENT, payload: e.target.value});
        } else {
            dispatch({type: CLEAR_STATE});
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            router.push('/productions')
        }
    }

    return (
        <div
            className="flex justify-between xs:w-64 sm:w-80 md:w-fit h-10 bg-white rounded-[90px] shadow-bx-light cursor-pointer">
            <input
                type="text"
                className="pl-2 sm:flex-1 w-44 sm:w-fit shadow-bx-light rounded-l-[90px] outline-none sm:px-2 xl:px-4 py-2 text-md placeholder-gray-dark font-cooper-hewitt-book"
                placeholder="Nach Stücken suchen"
                value={searchQuery}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
            <div className='pr-2 my-2 sm:mx-2'>
                <div onClick={handleSearchClick}
                     className='hover:scale-125'>{SvgSearchIcon()}</div>
            </div>
        </div>
    );
}
