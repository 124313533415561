export default function Offers() {
    return (
        <div>
            <div className="w-full bg-[#F5F2ED] mt-16">
                <h1 className="text-gray-dark w-fit h-12 text-lg xl:text-2xl pt-24 ml-[20%] ">
                    Angebote für Gruppen <br/> und Schulklassen
                </h1>
                <div className='text-gray-dark text-base p-8 xl:pl-0 xl:ml-[30%] mt-44 h-fit'>
                    <p>
                        <h1>Herzlich willkommen in unserem Digitalen Theater!</h1>
                        Genau wie in unserem Theater in
                        Bonn spielen wir auch digital fast jeden Vormittag für
                        Schulklassen und Gruppen. Unser Spielplan bietet viele verschiedene Stücke für Kinder und
                        Jugendliche in
                        jedem Alter, die sich hervorragend auch für den gemeinsamen Besuch mit einer Klasse und für
                        den
                        Einsatz im Fachunterricht eignen.

                        <br/><br/>

                        <h1>Termine nach Wunsch</h1>
                        Im Spielplan finden Sie täglich mindestens eine Vorstellung von einem unserer Stücke. Wir
                        können
                        Ihnen aber von vielen unserer Stücke auch eine Vorstellung an Ihrem Wunschtermin anbieten.
                        Bitte
                        schreiben Sie uns eine Mail oder rufen Sie uns an, wenn Sie eine Vorstellung an einem
                        Wunschtermin buchen möchten.

                        <br/><br/>

                        <h1>Didaktisches Begleitmaterial</h1>
                        Zu vielen unserer Stücke haben wir ein theater- und/oder medienpädagogisches Materialheft
                        herausgegeben, das Lehrer*innen viele Anregungen zur Vor- und Nachbereitung des (digitalen)
                        Theaterbesuchs liefert und die vertiefende Behandlung der Themen des jeweiligen Stücks im
                        Schulunterricht fördert. Insbesondere die beiden digital produzierten Stücke ’TKKG - Gefangen in
                        de Vergangenheit’
                        und ‚Die Surfguards - Nur das eine Leben‘ sind auch für den Einsatz im Rahmen der
                        Medienpädagogik konzipiert und geeignet.
                        Die Begleitmaterialien finden Sie als PDF zum Download auf der Seite des jeweiligen Stücks

                        <br/><br/>

                        <h1>Digitales Nachgespräch mit dem Ensemble</h1>
                        Auch bei unseren Online-Vorstellungen bieten wir Gruppen ein moderiertes Nachgespräch mit
                        den Schauspielerinnen und ggf. dem oder der Regisseurin an, wenn es terminlich möglich ist. Das
                        Nachgespräch kann unmittelbar nach der Onlinevorstellung oder auch an einem der folgenden
                        Tage verabredet werden.

                        <br/><br/>

                        <h1 className="text-lg">Gruppentarif</h1>
                        <br/>
                        <p>Bitte beachten Sie unbedingt, dass Sie ein Gruppenticket kaufen müssen, wenn Sie mit
                            einer
                            Klasse oder Gruppe eine unserer Vorstellungen online besuchen wollen.</p>
                        <br/>
                        <h1>
                            Für Schulklassen und Gruppen gilt für alle Online-Vorstellungen ein einheitlicher
                            Eintrittspreis:
                            Eine Klasse/Gruppe mit 26 bis 34 Schüler*innen 120,- Euro / Klasse
                        </h1>
                        Kleinere Gruppen (bis 25 Schülerinnen) 4,50 Euro / Schülerin
                        Größere Gruppen (ab 35 Schülerinnen) 3,50 Euro / Schüler*in

                        <br/><br/>

                        <h1>Grundsätzlich gilt der Gruppenpreis für das gemeinsame Schauen des Stückes an einem Ort
                            über Beamer, Whiteboard oder Leinwand.</h1>
                        Auf Anfrage können wir Ihnen aber gern auch individuelle Links zuschicken, so dass
                        Schüler*innen, die im Homeschooling sind oder aus anderen Gründen nicht an einem Ort
                        zusammenkommen können, von zuhause aus das Stück gemeinsam sehen können.

                        <br/><br/>

                        <h1>Unter <a className="underline" href="/faq">Hilfe und FAQ</a> finden Sie alle technischen
                            Hinweise</h1>
                        <br/><br/>
                    </p>
                </div>
            </div>
            <div
                className='bg-gray-dark h-8 flex items-center justify-center text-white text-base uppercase'>
                <a href="/impressum" className="mr-12">Impressum</a>
                <a href="/datenschutz" className="mr-12">Datenschutz</a>
                <a href="https://www.jt-bonn.de/unternehmen/allgemeine-geschaeftsbedingungen/" className="mr-12">AGB</a>
                <a href="/faq">FAQ</a>
            </div>
        </div>
    )
        ;
}
