import Button from "/components/shared/buttons/Button";
import GoldButton from "/components/shared/buttons/GoldButton";
import {SvgBMeeting, SvgTheater1, SvgTicket1} from '/components/shared/SvgIcons';
import {useRouter} from "next/router";

export default function InformationPart() {
    const router = useRouter();
    return (
        <div className="bg-white w-fit grid-col-3" id="information">
            <div className="2xl:flex 2xl:justify-between items-center mx-72 lg:mt-28 ">
                <h1 className="hidden 2xl:block text-gray-dark font-cooper text-xl">
                    Das digitale Theater
                </h1>
                <div className='hidden 2xl:block'>
                    <GoldButton
                        onClick={() => router.push('/foyer')}
                        style='text-md pt-2 pb-1 px-4 rounded-full'>
                        Zum Digitalen Foyer
                    </GoldButton>
                </div>
            </div>
            <h1 className="2xl:hidden flex justify-center md:justify-start text-gray-dark w-fit font-cooper text-lg md:text-xl mt-14 ml-8">
                Das digitale Foyer
            </h1>
            <div
                className="grid grid-rows-3 gap-12 lg:gap-2 place-content-center lg:grid-cols-3 lg:flex mt-8 font-light text-sm sm:text-md 2xl:text-base">
                <div className="flex flex-row lg:flex-col">
                    {SvgTheater1()}
                    <p className="w-[250px] sm:w-[339px] px-4 lg:mt-[24px]">
                        Über unser digitales Theater ganz einfach Theaterstücke bequem von
                        zu Hause aus im Stream ansehen
                    </p>
                </div>

                <div className="flex flex-row lg:flex-col">
                    {SvgBMeeting()}
                    <p className="w-[250px] sm:w-[339px] px-4 lg:mt-[24px]">
                        Nach der Vorstellung treffen wir uns im digitalen Foyer für einen
                        Austausch zur Vorstellung.
                    </p>
                </div>
                <div className="flex flex-row lg:flex-col">
                    {SvgTicket1()}
                    <p className="w-[250px] sm:w-[339px] lg:mt-[24px] px-4 lg:mt-[24px]">
                        Tickets einfach online kaufen. Wir bieten vergünstigte Tickets für
                        Schulklassen und Gruppen.
                    </p>
                </div>
            </div>

            <div className='flex justify-center 2xl:hidden'>
                <Button buttonStyle="bg-gold rounded-[4px] w-[227px] h-[44px] my-12 ">
                    <h1 className="text-white text-sm uppercase text-center">
                        Zum Digitalen Foyer
                    </h1>
                </Button>
            </div>

            <div className="hidden xl:block 2xl:grid 2xl:place-content-center 2xl:mt-8 ">
                <img src="/assets/images/image5.png" className='object-scale-down'/>
                <p className='w-fit mt-2 text-gray-dark text-sm '>aus "Die unendliche
                    Geschichte"</p>
            </div>

            <div className="xl:hidden">
                <img src="/assets/images/image5.png" className='object-scale-down'/>
                <h1 className='w-fit text-gray-dark text-sm font-light ml-4'>aus "Die unendliche
                    Geschichte"</h1>
            </div>
        </div>
    );
}