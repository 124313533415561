import React, {useContext} from "react";
import RoomButton from "/components/shared/buttons/RoomButton";
import Search from "/components/shared/Search";
import CarouselLandingPage from '/components/shared/carousel/CarouselLandingPage';
import GoldButton from "/components/shared/buttons/GoldButton";
import AppContext from "/context/AppContext";
import {SvgTailRight1, SvgTheaterCurtains1} from "/components/shared/SvgIcons";
import {useRouter} from "next/router";

export default function CurrentShows() {
    const {productions} = useContext(AppContext);
    const router = useRouter();

    return (
        <div className="w-fit h-[759px] 2xl:h-[766px] bg-gray-dark flex items-stretch overflow-x-hidden">
          <div className='absolute w-screen flex justify-center items-center mt-12 md:hidden '>
                <div className='flex-col'>
                    <RoomButton href='/schedule' className='py-2 px-4 mb-12'>
                        <div className="mx-3">{SvgTheaterCurtains1("#AC8243")}</div>
                        Spielplan
                    </RoomButton>
                </div>
          </div>
          <div className='flex flex-col justify-center'>
          <div className="w-screen px-8 md:px-0 py-8 flex flex-row justify-center items-center">
            <img
              className=''
              src='/assets/images/neustart-kultur-logo.jpg'
            />
          </div>
                <div className="flex md:ml-44 2xl:ml-0">
                    <div
                        className='w-[30vw] mr-24 pl-4 mt-14 xl:pl-52 md:mr-10 md:mt-12 2xl:mt-14 z-10 bg-gray-dark'>
                        <h1 className="text-white text-lg sm:text-xl 2xl:text-2xl">
                            Aktuelle <br/>Stücke
                        </h1>

                        <p className="text-white w-44 md:w-52 h-[56px] text-base mt-[26px]">
                            Online ansehen und gemeinsam erleben.
                        </p>

                        <div className='mt-12 2xl:hidden'>
                            {SvgTailRight1()}
                        </div>
                        <div className='hidden 2xl:block mt-12'>
                            <GoldButton
                                onClick={() => router.push('/productions')}
                                style='text-md pt-2 pb-1 px-4 rounded-[44px]'>
                                Alle Stücke
                            </GoldButton>
                        </div>
                    </div>
                    <div className='w-[70vw] sm:w-screen overflow-hidden'>
                        <CarouselLandingPage productions={productions}/>
                    </div>
                </div>
                <div className='absolute flex justify-center items-center w-screen mt-100 z-10'>
                    <Search/>
                </div>
            </div>
        </div>
    );
}
