import {SvgNumber1, SvgNumber2, SvgNumber3} from "/components/shared/SvgIcons";
import Link from "next/link";

export default function ExploreSteps() {
    return (
        <div className='w-fit ml-4 xl:ml-[20vw]'>
            <div className="bg-white mt-12">
                <h1 className="text-lg md:text-xl 2xl:text-2xl md:ml-32 2xl:ml-0 text-gray-dark">
                    In wenigen Schritten <br/> zum online Erlebnis
                </h1>
                <div
                    className="w-64 sm:w-72 md:w-100 2xl:w-[720px] ml-4 sm:ml-10 md:ml-44 mt-12">
                    <div className="flex">
                        <div>
                            {SvgNumber1()}
                        </div>
                        <div className="ml-4 w-fit">
                            <h1 className="text-gray-dark text-md md:text-lg">
                                Das digitale Theater erkunden
                            </h1>
                            <p className="text-sm md:text-base text-gray-dark md:mt-[11px] mb-4">
                                Erleben Sie unser Foyer online und entdecken Sie unsere Theaterstücke
                                interaktiv.
                            </p>
                            <Link href="/foyer">
                                <a className="font-bold text-gold text-xs-3 md:text-base underline tracking-tight uppercase">
                                    Zum digitalen Foyer
                                </a>
                            </Link>
                        </div>
                    </div>
                    <div className="flex mt-12 md:mt-[84px] ">
                        <div>
                            {SvgNumber2()}
                        </div>
                        <div className="ml-4 w-fit">
                            <h1 className="text-gray-dark text-md md:text-lg">
                                Das passende Stück auswählen
                            </h1>
                            <p className="text-sm sm:text-base text-gray-dark mt-[11px]  ">
                                Wählen Sie ein Theaterstück aus und buchen Sie ein Ticket direkt über unsere
                                Seite. Zum Ticket bekommen Sie Zugriff zu interessanten
                                zusätzlichen Inhalten wie Material für Lehrer*innen.
                            </p>
                        </div>
                    </div>

                    <div className="flex mt-12 md:mt-[84px]">
                        <div>
                            {SvgNumber3()}
                        </div>
                        <div className="ml-4 w-fit mb-8">
                            <h1 className="text-gray-dark sm:mb-0 text-md md:text-lg">
                                Gemeinsam online ansehen
                            </h1>
                            <p className="text-sm sm:text-base text-gray-dark mt-[11px] ">
                                Unsere Streams finden in der Regel zu einer bestimmten Uhrzeit
                                statt, damit wir zusammen das Erlebnis teilen. Das ermöglicht auch
                                spannende Gespräche und Austausch im digitalen Foyer nach der
                                Veranstaltung.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
